<template>
  <div class="dashboard-content">
    <div class="row">
       <div class="col-lg-12 col-md-12">
        <div class="dashboard-list-box mb-5">
          <div class="dashboard-list-box-static">
           <div class="row">
                <div class="col-md-6">
                    <h6 class="mb-2" style="text-transform:uppercase">
                    Select The Ad You Want To Purchase
                   
                    </h6>
                     <span class="font-weight-bold"> <i class="fa fa-map-marker" aria-hidden="true"></i> 77007 Lake, Forest, Houston, TX 77007</span>
                </div>
                <div class="col-md-6 text-right">
                    <button @click="deleteBusiness" class="btn btn-danger btn-sm"> <i class="fa fa-trash" aria-hidden="true"></i> Delete Business </button>
                </div>
           </div>
            <hr>
            <div class="row">
                <div class="col-md-8 table-responsive">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="/purchase-realtor-ad">Realtor Ad - Purchase/Edit - Your Zip Code</router-link>
                                </td>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="#">
                                        <button class="btn btn-danger btn-sm">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                Delete Ad
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                            <tr>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="#">Sponsor Ad - Purchase/Edit - Your Zip Code</router-link>
                                </td>
                                <td scope="col" class="uppercase" > 
                                    <router-link to="#">
                                        <button class="btn btn-danger btn-sm">
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                                Delete Ad
                                        </button>
                                    </router-link>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="copyrights">
          © 2021 Thriggle. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../../../services/http-common';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
export default {
    data(){
        return {
            zipcodes: []
        }
    },
    components: {
      vSelect:vSelect,
	},
    methods:{
        fetchZipCode(){
            return [];
        },
        deleteBusiness(){
            this.$swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                this.$swal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
                )
            }
        })
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.dashboard-list-box ul li{
    padding: 13px 30px !important;
}
.uppercase{
    text-transform: uppercase !important;
}
</style>
